@use '../abstracts/variables';
@use '../abstracts/mixins';
@use '@angular/material' as mat;
@include mat.core();

* {
  @include mat.core-theme($zero-light-theme);
  @include mat.all-component-themes($zero-light-theme);

  --arc-color-primary: var(--arc-purple-060);

  --zero-color-logo: rgb(var(--arc-purple-060));
  --zero-background-svg: rgb(var(--arc-purple-060));
  --zero-card-background: rgb(var(--arc-background-color));
  --zero-asset-type: rgb(227, 178, 238);
  --zero-systems-card-embodied-background: rgb(var(--arc-grey-090));
  --zero-systems-card-embodied-font: rgb(var(--arc-white-000));
  --zero-systems-card-operational-background: rgb(var(--arc-grey-020));
  --zero-systems-card-operational-font: rgb(var(--arc-grey-100));
  --zero-asset-card-graphic-background: rgb(var(--arc-grey-010));
  --zero-chart-background: rgb(240, 231, 230);
  --zero-carbon-table-background: rgb(var(--arc-container-color));
  --zero-carbon-table-input-background: rgb(var(--arc-grey-010));
  --zero-carbon-table-cell-operational-background: rgb(var(--arc-grey-020));
  --zero-carbon-table-cell-biogenic-background: rgb(212, 228, 233);
  --zero-sorting-copy: rgb(var(--arc-grey-060));
  --zero-sorting-background: rgb(var(--arc-grey-010));
  --zero-sorting-background-selected: rgb(var(--arc-grey-020));
  --zero-sorting-background-hover: rgb(var(--arc-grey-020));
  --zero-sorting-background-focus: rgb(var(--arc-grey-030));
  --zero-system-modal-header-background: rgb(var(--arc-grey-010));
  --zero-tag-background: rgb(var(--arc-grey-010));
  --zero-project-progress-bar-background: rgb(var(--arc-purple-010));
  --zero-project-progress-bar-background-active: rgb(var(--arc-purple-020));
  --zero-project-progress-step-background: rgb(var(--arc-grey-010));
  --zero-project-progress-step-background-active: rgb(var(--arc-purple-050));
  --zero-project-progress-assessment-background: rgb(var(--arc-grey-020));
  --zero-project-progress-assessment-background-active: rgb(
    var(--arc-purple-050)
  );
}
