/* ARC BUTTON STYLES */
arc-button {
  --arc-input-box-shadow: none; // remove shadows from all ARC buttons
  &[type='outlined'] {
    --arc-border-radius-medium: 2rem; // add rounded pill shaped borders to all ARC outline buttons
    mat-icon {
      transform: scale(0.7);
    }
  }
}
