div.zero-assessment-list {
  display: grid;

  zero-assessment-card {
    border-bottom: var(--arc-border-width) var(--arc-border-style)
      rgba(var(--arc-font-color), 0.3);

    &:last-child {
      border-bottom: none;
    }
  }
}
