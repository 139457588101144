arc-navbar {
  --arc-font-color: var(--arc-color-primary) !important;

  @include responsive-breakpoint('large') {
    --arc-box-shadow: none;
  }

  [slot='user'] {
    display: grid;
    align-items: center;
    justify-items: center;
    --padding: var(--arc-spacing-small) var(--arc-spacing-medium);
    --margin: 0;
    --button-background-color: transparent;

    --button-color: rgb(var(--arc-grey-100));
    --button-color--hover: rgb(var(--arc-grey-100));
    --button-background-color--hover: rgba(var(--arc-color-primary), 0.1);
    --popup-background-color: rgb(var(--arc-background-color));
    --popup-color: rgb(var(--arc-font-color));
  }
}

arc-navbar[env='test'] {
  --arc-font-color: var(--arc-white-000) !important;
  --arc-color-primary: var(--arc-white-000) !important;
  --arc-container-color: var(--arc-purple-060) !important;

  [slot='user'] {
    --color: var(--arc-white-000);
    --button-color: rgb(var(--arc-white-000)) !important;
    --button-color--hover: rgb(var(--arc-white-000)) !important;
    --button-background-color--hover: rgba(
      var(--arc-white-000),
      0.1
    ) !important;
    --popup-color: rgb(var(--arc-grey-100)) !important;
  }
}

arc-container[theme='dark'] {
  arc-navbar {
    [slot='user'] {
      --button-color: rgb(var(--arc-white-000));
      --button-color--hover: rgb(var(--arc-white-000));
    }
  }

  arc-navbar[env='test'] {
    [slot='user'] {
      --popup-color: rgb(var(--arc-white-000)) !important;
    }
  }
}
