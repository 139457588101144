mat-vertical-stepper {
  background-color: rgb(var(--arc-container-color)) !important;

  div.mat-step {
    mat-step-header {
      background-color: rgb(var(--arc-grey-090));
      margin-bottom: var(--arc-spacing-normal);
      border-radius: var(--arc-border-radius-medium);
      pointer-events: none;

      &:hover,
      &:focus {
        background-color: rgb(var(--arc-grey-090));
      }

      div.mat-step-text-label {
        color: rgb(var(--arc-grey-000));
        font-size: var(--arc-font-size-x-large);
      }

      div.mat-step-icon {
        display: none;
      }
    }

    div.mat-vertical-content-container {
      margin-left: 0px;

      div.form-controls {
        display: flex;
        flex-direction: row;
        gap: var(--arc-spacing-small);
        justify-content: right;
        z-index: 0;
      }
    }

    div.mat-stepper-vertical-line::before {
      display: none;
    }

    div.mat-vertical-stepper-content {
      div.mat-vertical-content {
        display: flex;
        flex-direction: column;
        gap: var(--arc-spacing-medium);
        padding-top: var(--arc-spacing-x-small);
      }
    }
  }

  &.zero-stepper--embodied {
    div.mat-step {
      mat-step-header {
        background-color: var(--zero-systems-card-embodied-background);

        &:hover,
        &:focus {
          background-color: var(--zero-systems-card-embodied-background);
        }

        div.mat-step-text-label {
          color: var(--zero-systems-card-embodied-font);
        }
      }
    }
  }

  &.zero-stepper--operational {
    div.mat-step {
      mat-step-header {
        background-color: var(--zero-systems-card-operational-background);
        &:hover,
        &:focus {
          background-color: var(--zero-systems-card-operational-background);
        }

        div.mat-step-text-label {
          color: var(--zero-systems-card-operational-font);
        }
      }
    }
  }

  &.zero-systems-stepper {
    div.mat-step {
      mat-step-header {
        background-color: transparent;
        pointer-events: none;
        margin-bottom: 0;
        display: grid;
        height: unset;

        &:hover,
        &:focus {
          background-color: transparent !important;
        }

        div.mat-step-label {
          white-space: unset;
          overflow: unset;
          text-overflow: unset;

          div.mat-step-text-label {
            color: rgb(var(--arc-font-color));
            font-size: var(--arc-font-size-medium);
            font-weight: var(--arc-font-weight-bold);

            div {
              background-color: var(--zero-card-background);
              border-left: thick solid black;
              padding: var(--arc-spacing-small);

              &.substructure {
                background-color: var(--zero-system-substructure);
              }

              &.superstructure {
                background-color: var(--zero-system-superstructure);
              }

              &.mechanical-services {
                background-color: var(--zero-system-mechanical-services);
              }

              &.electrical-services {
                background-color: var(--zero-system-electrical-services);
              }

              &.building-envelope {
                background-color: var(--zero-system-building-envelope);
              }

              &.public-health-and-hydraulics {
                background-color: var(
                  --zero-system-public-health-and-hydraulics
                );
              }

              &.space-plan {
                background-color: var(--zero-system-space-plan);
              }
            }
          }
        }
      }
    }
  }
}
