@use '@angular/material' as mat;

body[theme='dark'] * {
  @include mat.core-color($zero-dark-theme);
  @include mat.all-component-colors($zero-dark-theme);

  --arc-font-color: var(--arc-white-100);
  --arc-color-primary: var(--arc-white-000);

  --zero-color-logo: rgb(var(--arc-white-000));
  --zero-background-svg: rgb(var(--arc-white-000));
  --zero-card-background: rgb(var(--arc-background-color));
  --zero-asset-type: rgb(227, 178, 238);
  --zero-systems-card-embodied-background: rgb(var(--arc-grey-010));
  --zero-systems-card-embodied-font: rgb(var(--arc-grey-100));
  --zero-systems-card-operational-background: rgb(var(--arc-grey-030));
  --zero-systems-card-operational-font: rgb(var(--arc-grey-100));
  --zero-asset-card-graphic-background: rgb(var(--arc-grey-050));
  --zero-chart-background: rgb(118, 97, 95);
  --zero-carbon-table-background: rgb(var(--arc-card-background));
  --zero-carbon-table-input-background: rgb(var(--arc-grey-080));
  --zero-carbon-table-cell-operational-background: rgb(var(--arc-grey-070));
  --zero-carbon-table-cell-biogenic-background: rgb(212, 228, 233);
  --zero-sorting-copy: rgb(var(--arc-grey-040));
  --zero-sorting-background: rgb(var(--arc-grey-090));
  --zero-sorting-background-selected: rgb(var(--arc-grey-080));
  --zero-sorting-background-hover: rgb(var(--arc-grey-080));
  --zero-sorting-background-focus: rgb(var(--arc-grey-070));
  --zero-system-modal-header-background: rgb(var(--arc-grey-090));
  --zero-tag-background: rgb(var(--arc-grey-010));
  --zero-project-progress-bar-background: rgb(var(--arc-purple-090));
  --zero-project-progress-bar-background-active: rgb(var(--arc-purple-060));
  --zero-project-progress-step-background: rgb(var(--arc-grey-080));
  --zero-project-progress-step-background-active: rgb(var(--arc-purple-040));
  --zero-project-progress-assessment-background: rgb(var(--arc-grey-080));
  --zero-project-progress-assessment-background-active: rgb(
    var(--arc-purple-040)
  );
}
