.mat-mdc-dialog-surface {
  border-radius: 0px !important;
  max-width: 560px !important;

  mat-dialog-actions {
    display: flex;
    gap: var(--arc-spacing-small);
  }

  .system-modal & {
    padding: 0px !important;
    max-width: 100% !important;

    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;

    header {
      display: flex;
      justify-content: space-between;
      padding: var(--arc-spacing-large) var(--arc-spacing-large);
    }

    mat-tab-group {
      margin: var(--arc-spacing-medium);
      font-size: var(--arc-font-size-x-small);
    }
  }
}
