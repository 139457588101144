:root,
:host,
arc-container[theme="light"] {
  /* Base */
  --arc-font-color: var(--arc-grey-100);
  --arc-input-color: var(--arc-font-color);
  --arc-background-color: var(--arc-grey-010);
  --arc-container-color: var(--arc-white-000);

  /* Palette */
  --arc-color-default: var(--arc-grey-010);
  --arc-color-primary: var(--arc-red-050);
  --arc-color-secondary: var(--arc-grey-070);
  --arc-color-error: var(--arc-red-040);
  --arc-color-warning: var(--arc-orange-040);
  --arc-color-info: var(--arc-blue-050);
  --arc-color-success: var(--arc-green-040);

  /* Box shadow */
  --arc-box-shadow: var(--arc-box-shadow-01) var(--arc-darker-15), var(--arc-box-shadow-03) var(--arc-darker-13);

  /* Hover */
  --arc-hover-darker: var(--arc-darker-30);
  --arc-hover-dark: var(--arc-darker-10);
  --arc-hover-light: var(--arc-lighter-70);
  --arc-hover-lighter: var(--arc-lighter-90);
}
