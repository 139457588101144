div.zero-detailed-assessment-energy-model-subsystem-form {
  display: grid;
  gap: var(--arc-spacing-small);

  @include responsive-breakpoint('x-large') {
    gap: revert;
  }

  div.subsystem-row {
    display: grid;
    gap: var(--arc-spacing-small);

    @include responsive-breakpoint('x-large') {
      grid-template-columns: 1fr 4fr;
      gap: revert;
      column-gap: var(--arc-spacing-small);
    }

    div.subsystem-name {
      background: rgba(var(--zero-color-logo), 0.1);
      display: flex;
      gap: var(--arc-spacing-small);
      align-items: center;
      padding: 0 var(--arc-spacing-small);
    }
  }

  div.form {
    display: grid;
    gap: var(--arc-spacing-small);
    align-items: center;

    @include responsive-breakpoint('x-large') {
      grid-template-columns: 1fr 1fr 1fr;

      &.pump-energy {
        grid-template-columns: 32.2% 1fr 1fr 1fr;
      }

      &.renewables,
      &.generator,
      &.distribution {
        grid-template-columns: 32.2% auto;
      }
    }

    div.alert {
      display: flex;
      gap: var(--arc-spacing-x-small);
      align-items: center;
    }

    div.form-field {
      border-bottom: var(--arc-border-style) var(--arc-border-width)
        rgb(var(--arc-font-color));

      label {
        color: rgba(var(--arc-font-color), 0.7);
        font-size: var(--arc-font-size-xx-small);
        user-select: none;
      }

      p {
        margin: 0;
      }
    }
  }
}
