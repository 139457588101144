button[mat-stroked-button],
button[mat-flat-button],
button[mat-icon-button],
a[mat-stroked-button],
a[mat-flat-button] {
  border-radius: 2rem;
  border-color: rgb(var(--arc-color-secondary)) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  mat-icon {
    transform: scale(0.7);
  }

  &[color='accent'] {
    color: var(--zero-white-000) !important;

    arc-container[theme='dark'] & {
      color: var(--zero-black-000) !important;
    }
  }

  &[color='transparent'] {
    background: transparent !important;
  }

  &.zero-button-outlined {
    color: rgb(var(--zero-color-logo)) !important;
    border-color: rgb(var(--zero-color-logo)) !important;
  }

  &.system-close {
    background: transparent;
    border: none;
    color: var(--zero-color-logo);
  }
}

button[mat-menu-item] {
  &.embodied {
    background: var(--zero-systems-card-embodied-background);
    color: var(--zero-systems-card-embodied-font);

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      background-color: rgba(var(--zero-systems-card-embodied-background), 0.8);
    }
  }
}
