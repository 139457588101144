$zero-screen-medium: 480px;
$zero-screen-large: 784px;
$zero-screen-x-large: 1025px;
$zero-screen-xx-large: 1400px;

$zero-breakpoints: (
  'medium': (
    min-width: $zero-screen-medium,
  ),
  'large': (
    min-width: $zero-screen-large,
  ),
  'x-large': (
    min-width: $zero-screen-x-large,
  ),
  'xx-large': (
    min-width: $zero-screen-xx-large,
  ),
);
