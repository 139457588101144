:host,
arc-container[theme="dark"] {
  /* Base */
  --arc-font-color: var(--arc-grey-000);
  --arc-input-color: var(--arc-font-color);
  --arc-background-color: var(--arc-grey-090);
  --arc-container-color: var(--arc-grey-100);

  /* Set the color scheme for native form elements */
  color-scheme: dark;

  /* Palette */
  --arc-color-default: var(--arc-background-color);
  --arc-color-primary: var(--arc-font-color);
  --arc-color-secondary: var(--arc-grey-040);
  --arc-color-error: var(--arc-red-040);
  --arc-color-warning: var(--arc-orange-040);
  --arc-color-info: var(--arc-blue-050);
  --arc-color-success: var(--arc-green-040);

  /* Box shadow */
  --arc-box-shadow:
    var(--arc-box-shadow-01) var(--arc-darker-30),
    var(--arc-box-shadow-02) var(--arc-darker-15),
    var(--arc-box-shadow-03) var(--arc-darker-13);

  /* Hover */
  --arc-hover-light: var(--arc-darker-85);
  --arc-hover-lighter: var(--arc-darker-80);
}
