span.zero-tag {
  background: var(--zero-tag-background);
  color: rgb(var(--arc-grey-100));
  font-size: var(--arc-font-size-x-small);
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  height: var(--arc-spacing-large);
  text-overflow: ellipsis;
  padding: calc(var(--arc-spacing-x-small) / 2) var(--arc-spacing-x-small);
  border-radius: var(--arc-border-radius-medium);
  user-select: none;

  &.status {
    background: var(--zero-asset-type) !important;
    color: rgb(var(--arc-grey-100));
  }

  &.location {
    display: inline-block;
    max-width: calc(var(--arc-spacing-large) * 4);
  }

  .active {
    color: rgb(var(--arc-green-060));
  }

  .inactive {
    color: rgb(var(--arc-red-060));
  }

  &.confidential {
    background-color: var(--zero-systems-card-embodied-background);
    color: var(--zero-systems-card-embodied-font);
  }

  &.notification {
    color: rgb(var(--arc-purple-060));
    background: transparent !important;
    border-radius: var(--arc-border-radius-x-large);
    border-color: rgb(var(--arc-purple-060));
    border: var(--arc-border-style) var(--arc-border-width);
    padding: calc(var(--arc-spacing-x-small) / 4) var(--arc-spacing-small);
  }

  &.ddb {
    color: rgb(var(--arc-orange-060));
    border-color: rgb(var(--arc-orange-060));
  }

  &.component-state {
    background: transparent !important;
    border-radius: var(--arc-border-radius-x-large);
    border: var(--arc-border-style) var(--arc-border-width);
    padding: calc(var(--arc-spacing-x-small) / 4) var(--arc-spacing-small);

    &.new {
      color: rgb(var(--arc-purple-060));
      border-color: rgb(var(--arc-purple-060));
    }

    &.edited {
      color: rgb(var(--arc-orange-060));
      border-color: rgb(var(--arc-orange-060));
    }

    &.deleted {
      color: rgb(var(--arc-red-060));
      border-color: rgb(var(--arc-red-060));
    }
  }

  mat-icon {
    transform: scale(0.6);
    margin-left: calc(var(--arc-spacing-x-small) * -1);
  }

  &.zero-subsystem-tag {
    background: transparent !important;
    gap: var(--arc-spacing-small);
    color: rgb(var(--arc-font-color));

    span.dot {
      width: var(--arc-font-size-small);
      height: var(--arc-font-size-small);
      background: rgb(var(--arc-grey-050));
      border-radius: var(--arc-border-radius-large);

      &.substructure {
        background: var(--zero-system-substructure);
      }

      &.superstructure {
        background: var(--zero-system-superstructure);
      }

      &.mechanical-services {
        background: var(--zero-system-mechanical-services);
      }

      &.electrical-services {
        background: var(--zero-system-electrical-services);
      }

      &.building-envelope {
        background: var(--zero-system-building-envelope);
      }

      &.public-health-and-hydraulics {
        background: var(--zero-system-public-health-and-hydraulics);
      }

      &.space-plan {
        background: var(--zero-system-space-plan);
      }

      &.ground-slab---basement-frame {
        background: var(--zero-subsystem-ground-slab---basement-frame);
      }

      &.foundation {
        background: var(--zero-subsystem-foundation);
      }

      &.basement-perimeter {
        background: var(--zero-subsystem-basement-perimeter);
      }

      &.floorplate {
        background: var(--zero-subsystem-floorplate);
      }

      &.vertical-structure {
        background: var(--zero-subsystem-vertical-structure);
      }

      &.stability {
        background: var(--zero-subsystem-stability);
      }

      &.roof {
        background: var(--zero-subsystem-roof);
      }

      &.stairs {
        background: var(--zero-subsystem-stairs);
      }

      &.heating {
        background: var(--zero-subsystem-heating);
      }

      &.cooling {
        background: var(--zero-subsystem-cooling);
      }

      &.ventilation {
        background: var(--zero-subsystem-ventilation);
      }

      &.process {
        background: var(--zero-subsystem-process);
      }

      &.electrical {
        background: var(--zero-subsystem-electrical);
      }

      &.telecomms---ict {
        background: var(--zero-subsystem-telecomms---ict);
      }

      &.lighting {
        background: var(--zero-subsystem-lighting);
      }

      &.renewables {
        background: var(--zero-subsystem-renewables);
      }

      &.vertical-transport {
        background: var(--zero-subsystem-vertical-transport);
      }

      &.security {
        background: var(--zero-subsystem-security);
      }

      &.hot-water {
        background: var(--zero-subsystem-hot-water);
      }

      &.cold-water {
        background: var(--zero-subsystem-cold-water);
      }

      &.waste-water {
        background: var(--zero-subsystem-waste-water);
      }

      &.piped-fire {
        background: var(--zero-subsystem-piped-fire-systems);
      }

      &.piped-fuel {
        background: var(--zero-subsystem-piped-fuel);
      }

      &.specialist {
        background: var(--zero-subsystem-specialist);
      }

      &.facade {
        background: var(--zero-subsystem-facade);
      }

      &.roof-finishes {
        background: var(--zero-subsystem-roof-finishes);
      }

      &.partitions {
        background: var(--zero-subsystem-partitions);
      }

      &.floor-finishes {
        background: var(--zero-subsystem-floor-finishes);
      }

      &.ceiling-finishes {
        background: var(--zero-subsystem-ceiling-finishes);
      }

      &.wall-finishes {
        background: var(--zero-subsystem-wall-finishes);
      }

      &.architectural-metalwork {
        background: var(--zero-subsystem-architectural-metalwork);
      }
    }
  }
}
