mat-form-field {
  .mat-mdc-text-field-wrapper {
    padding-right: 16px;
  }

  .mat-mdc-form-field-infix {
    width: 100%;
  }
}

mat-form-field:has(mgt-people-picker) {
  z-index: 1;

  .mat-mdc-text-field-wrapper {
    overflow: visible;
  }
}
