:root,
* {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-weight: 300;

  strong {
    font-weight: var(--arc-font-weight-bold);
  }
}

a {
  color: var(--zero-color-logo);
  font-weight: var(--arc-font-weight-bold);
}

h1 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  font-size: var(--arc-font-size-xx-large);
  line-height: var(--arc-line-height-dense);
}

h2 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  font-size: var(--arc-font-size-x-large);
  color: rgb(var(--arc-color-primary));
}

h3 {
  font-weight: 400;
}

.asset-details-typography {
  font-size: var(--arc-font-size-x-small);
  font-weight: var(--arc-font-weight-light);
  line-height: var(--arc-line-height-dense);

  * {
    margin: 0px;
  }

  strong {
    font-weight: var(--arc-font-weight-normal);
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
