div.zero-page {
  padding: 2rem 1rem;
  margin: 0px auto;

  &-medium {
    @extend .zero-page;
    max-width: $zero-screen-medium;
  }

  &-large {
    @extend .zero-page;
    max-width: $zero-screen-large;
  }

  &-x-large {
    @extend .zero-page;
    max-width: $zero-screen-x-large;
  }

  &-xx-large {
    @extend .zero-page;
    max-width: $zero-screen-xx-large;
  }
}
