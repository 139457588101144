div.zero-asset-form {
  display: grid;
  row-gap: var(--arc-spacing-large);
  column-gap: calc(var(--arc-spacing-large) * 2);

  @include responsive-breakpoint('large') {
    grid-template-columns: 150px 1fr;
  }

  div.heading {
    span {
      display: flex;
      gap: var(--arc-spacing-small);
      align-items: center;

      h3 {
        margin: 0px;
      }
    }
  }

  div.form {
    display: grid;
    gap: var(--arc-spacing-small);

    span mat-form-field {
      width: 100%;
    }

    span.split {
      display: flex;
      gap: var(--arc-spacing-small);
    }

    span.toggle {
      display: flex;
      gap: var(--arc-spacing-small);
      align-items: center;
      margin-bottom: var(--arc-spacing-large);
    }

    @include responsive-breakpoint('large') {
      grid-template-columns: 1fr 1fr;

      *.full-width {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}
