mat-select {
  mat-select-trigger {
    height: auto;

    div.systems {
      display: flex;
      flex-wrap: wrap;
      gap: var(--arc-spacing-x-small);
    }
  }
}
