div.zero-chart-assessments {
  display: grid;
  row-gap: calc(var(--arc-spacing-small) / 2);
  grid-template-columns: 1fr 50px;
  overflow: hidden;

  span.zero-chart-row {
    grid-column: 1;
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: var(--arc-spacing-small);
    height: var(--arc-spacing-small);
    align-items: center;

    span.zero-chart-axis {
      width: 60px;
      display: flex;
      align-items: center;
      height: var(--arc-font-size-x-small);
    }

    span.zero-carbon-stage-chart-bar {
      position: relative;
      display: flex;
      gap: 1px;
      width: 100%;
      align-self: stretch;

      &.empty-bar {
        width: 0 !important;
      }

      span.zero-carbon-stage-chart-section {
        width: 100%;
      }

      span.zero-chart-label {
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translate(100%, -50%);
        flex-shrink: 0;
        width: 100vw;
        flex-basis: calc(var(--arc-spacing-large) * 3.5);
      }
    }

    span.zero-chart-label {
      white-space: nowrap;
    }
  }
}
