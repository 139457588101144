mat-option {
  border-bottom: 1px solid rgba(var(--arc-font-color), 0.12);

  .mdc-list-item__primary-text {
    font-size: var(--arc-font-size-small);
    line-height: var(--arc-line-height-dense);
    margin: var(--arc-spacing-small) 0;
  }

  &:last-child {
    border-bottom: none;
  }
}
