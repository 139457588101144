div.zero-chart {
  font-size: var(--arc-font-size-x-small);
  user-select: none;

  span.zero-carbon-stage-chart-section {
    background: rgb(var(--arc-grey-050));

    &.substructure {
      background: var(--zero-system-substructure);
    }

    &.superstructure {
      background: var(--zero-system-superstructure);
    }

    &.mechanical-services {
      background: var(--zero-system-mechanical-services);
    }

    &.electrical-services {
      background: var(--zero-system-electrical-services);
    }

    &.building-envelope {
      background: var(--zero-system-building-envelope);
    }

    &.public-health-and-hydraulics {
      background: var(--zero-system-public-health-and-hydraulics);
    }

    &.space-plan {
      background: var(--zero-system-space-plan);
    }

    &.ground-slab---basement-frame {
      background: var(--zero-subsystem-ground-slab---basement-frame);
    }

    &.foundation {
      background: var(--zero-subsystem-foundation);
    }

    &.basement-perimeter {
      background: var(--zero-subsystem-basement-perimeter);
    }

    &.floorplate {
      background: var(--zero-subsystem-floorplate);
    }

    &.vertical-structure {
      background: var(--zero-subsystem-vertical-structure);
    }

    &.stability {
      background: var(--zero-subsystem-stability);
    }

    &.roof {
      background: var(--zero-subsystem-roof);
    }

    &.stairs {
      background: var(--zero-subsystem-stairs);
    }

    &.heating {
      background: var(--zero-subsystem-heating);
    }

    &.cooling {
      background: var(--zero-subsystem-cooling);
    }

    &.ventilation {
      background: var(--zero-subsystem-ventilation);
    }

    &.process {
      background: var(--zero-subsystem-process);
    }

    &.electrical {
      background: var(--zero-subsystem-electrical);
    }

    &.telecomms---ict {
      background: var(--zero-subsystem-telecomms---ict);
    }

    &.lighting {
      background: var(--zero-subsystem-lighting);
    }

    &.renewables {
      background: var(--zero-subsystem-renewables);
    }

    &.vertical-transport {
      background: var(--zero-subsystem-vertical-transport);
    }

    &.security {
      background: var(--zero-subsystem-security);
    }

    &.hot-water {
      background: var(--zero-subsystem-hot-water);
    }

    &.cold-water {
      background: var(--zero-subsystem-cold-water);
    }

    &.waste-water {
      background: var(--zero-subsystem-waste-water);
    }

    &.piped-fire {
      background: var(--zero-subsystem-piped-fire-systems);
    }

    &.piped-fuel {
      background: var(--zero-subsystem-piped-fuel);
    }

    &.specialist {
      background: var(--zero-subsystem-specialist);
    }

    &.facade {
      background: var(--zero-subsystem-facade);
    }

    &.roof-finishes {
      background: var(--zero-subsystem-roof-finishes);
    }

    &.partitions {
      background: var(--zero-subsystem-partitions);
    }

    &.floor-finishes {
      background: var(--zero-subsystem-floor-finishes);
    }

    &.ceiling-finishes {
      background: var(--zero-subsystem-ceiling-finishes);
    }

    &.wall-finishes {
      background: var(--zero-subsystem-wall-finishes);
    }

    &.architectural-metalwork {
      background: var(--zero-subsystem-architectural-metalwork);
    }
  }
}
