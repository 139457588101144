input:not(.zero-input-spin)::-webkit-outer-spin-button,
input:not(.zero-input-spin)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number']:not(.zero-input-spin) {
  -moz-appearance: textfield;
}

div.cell-input {
  background: var(--zero-carbon-table-input-background);
  border-radius: var(--arc-border-radius-medium);
  padding: calc(var(--arc-spacing-x-small) / 2);
  display: flex;

  input[matInput] {
    text-align: center;
  }
}
