mat-tab-group {
  mat-tab-header {
    div[role='tab'][aria-selected='true'] {
      opacity: 1;
    }
  }

  mat-tab-body {
    padding: var(--arc-spacing-small);
  }

  &.zero-subsystem-tabs {
    mat-ink-bar {
      display: none;
    }

    &.substructure {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-substructure);
          color: var(--zero-black-100);
        }
      }

      mat-tab-body {
        border: var(--arc-border-width) var(--arc-border-style)
          var(--zero-system-substructure);
      }
    }

    &.superstructure {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-superstructure);
          color: var(--zero-white-000);
        }
      }

      mat-tab-body {
        border: var(--arc-border-width) var(--arc-border-style)
          var(--zero-system-superstructure);
      }
    }

    &.mechanical-services {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-mechanical-services);
          color: var(--zero-white-000);
        }
      }

      mat-tab-body {
        border: var(--arc-border-width) var(--arc-border-style)
          rgb(
            var(
              --zero-system-mechanical-services-system-color-public-health-and-hydraulics
            )
          );
      }
    }

    &.electrical-services {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-electrical-services);
          color: var(--zero-white-000);
        }
      }

      mat-tab-body {
        border: var(--arc-border-width) var(--arc-border-style)
          var(--zero-system-electrical-services);
      }
    }

    &.building-envelope {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-building-envelope);
          color: var(--zero-black-100);
        }
      }

      mat-tab-body {
        border: var(--arc-border-width) var(--arc-border-style)
          var(--zero-system-building-envelope);
      }
    }

    &.public-health-and-hydraulics {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: rgb(var(--zero-system-public-health-and-hydraulics));
          color: var(--zero-black-100);
        }
      }

      mat-tab-body {
        border: var(--arc-border-width) var(--arc-border-style)
          var(--zero-system-public-health-and-hydraulics);
      }
    }

    &.space-plan {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-space-plan);
          color: var(--zero-white-000);
        }
      }

      mat-tab-body {
        border: var(--arc-border-width) var(--arc-border-style)
          var(--zero-system-space-plan);
      }
    }
  }

  &.zero-system-tabs {
    mat-ink-bar {
      display: none;
    }

    &.substructure {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-substructure);
          color: var(--zero-black-100);
        }
      }
    }

    &.superstructure {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-superstructure);
          color: var(--zero-white-000);
        }
      }
    }

    &.mechanical-services {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-mechanical-services);
          color: var(--zero-white-000);
        }
      }
    }

    &.electrical-services {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-electrical-services);
          color: var(--zero-white-000);
        }
      }
    }

    &.building-envelope {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-building-envelope);
          color: var(--zero-black-100);
        }
      }
    }

    &.public-health-and-hydraulics {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: rgb(var(--zero-system-public-health-and-hydraulics));
          color: var(--zero-black-100);
        }
      }
    }

    &.space-plan {
      mat-tab-header {
        div[role='tab'][aria-selected='true'] {
          background: var(--zero-system-space-plan);
          color: var(--zero-white-000);
        }
      }
    }
  }
}
