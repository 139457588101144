@use '@angular/material' as mat;

$zero-material-density: -1;

:root * {
  --zero-white-000: rgb(var(--arc-white-000));
  --zero-black-100: rgb(var(--arc-black-100));
  --zero-primary-100: rgb(var(--arc-grey-010));
  --zero-primary-200: rgb(var(--arc-grey-020));
  --zero-primary-300: rgb(var(--arc-grey-030));
  --zero-primary-300: rgb(var(--arc-grey-030));
  --zero-primary-400: rgb(var(--arc-grey-040));
  --zero-primary-500: rgb(var(--arc-grey-050));
  --zero-primary-600: rgb(var(--arc-grey-060));
  --zero-primary-700: rgb(var(--arc-grey-070));
  --zero-primary-800: rgb(var(--arc-grey-080));
  --zero-primary-900: rgb(var(--arc-grey-090));
  --zero-accent-100: rgb(var(--arc-purple-010));
  --zero-accent-200: rgb(var(--arc-purple-020));
  --zero-accent-300: rgb(var(--arc-purple-030));
  --zero-accent-400: rgb(var(--arc-purple-040));
  --zero-accent-500: rgb(var(--arc-purple-050));
  --zero-accent-600: rgb(var(--arc-purple-060));
  --zero-accent-700: rgb(var(--arc-purple-070));
  --zero-accent-800: rgb(var(--arc-purple-080));
  --zero-accent-900: rgb(var(--arc-purple-090));
  --zero-warn-100: rgb(var(--arc-red-010));
  --zero-warn-200: rgb(var(--arc-red-020));
  --zero-warn-300: rgb(var(--arc-red-030));
  --zero-warn-400: rgb(var(--arc-red-040));
  --zero-warn-500: rgb(var(--arc-red-050));
  --zero-warn-600: rgb(var(--arc-red-060));
  --zero-warn-700: rgb(var(--arc-red-070));
  --zero-warn-800: rgb(var(--arc-red-080));
  --zero-warn-900: rgb(var(--arc-red-090));
  --zero-system-superstructure: rgb(16, 75, 101);
  --zero-system-substructure: rgb(157, 237, 218);
  --zero-system-mechanical-services: rgb(255, 177, 179);
  --zero-system-electrical-services: rgb(125, 65, 150);
  --zero-system-public-health-and-hydraulics: rgb(227, 178, 238);
  --zero-system-space-plan: rgb(30, 152, 215);
  --zero-system-building-envelope: rgb(108, 201, 246);
  --zero-subsystem-ground-slab---basement-frame: rgb(157, 237, 218);
  --zero-subsystem-foundation: rgb(145, 214, 197);
  --zero-subsystem-basement-perimeter: rgb(223, 249, 243);
  --zero-subsystem-floorplate: rgb(16, 75, 101);
  --zero-subsystem-vertical-structure: rgb(33, 128, 139);
  --zero-subsystem-stability: rgb(50, 164, 160);
  --zero-subsystem-roof: rgb(71, 202, 202);
  --zero-subsystem-stairs: rgb(145, 244, 238);
  --zero-subsystem-heating: rgb(197, 79, 100);
  --zero-subsystem-cooling: rgb(250, 119, 124);
  --zero-subsystem-ventilation: rgb(255, 177, 179);
  --zero-subsystem-process: rgb(255, 217, 217);
  --zero-subsystem-electrical: rgb(196, 158, 235);
  --zero-subsystem-telecomms---ict: rgb(167, 107, 199);
  --zero-subsystem-lighting: rgb(125, 65, 150);
  --zero-subsystem-renewables: rgb(100, 69, 166);
  --zero-subsystem-vertical-transport: rgb(79, 30, 117);
  --zero-subsystem-security: rgb(125, 65, 150);
  --zero-subsystem-hot-water: rgb(201, 76, 166);
  --zero-subsystem-cold-water: rgb(190, 116, 208);
  --zero-subsystem-waste-water: rgb(229, 129, 207);
  --zero-subsystem-piped-fire-systems: rgb(227, 178, 238);
  --zero-subsystem-piped-fuel: rgb(227, 178, 238);
  --zero-subsystem-specialist: rgb(227, 178, 238);
  --zero-subsystem-facade: rgb(108, 201, 246);
  --zero-subsystem-roof-finishes: rgb(195, 234, 254);
  --zero-subsystem-partitions: rgb(149, 194, 248);
  --zero-subsystem-floor-finishes: rgb(30, 152, 215);
  --zero-subsystem-ceiling-finishes: rgb(30, 94, 189);
  --zero-subsystem-wall-finishes: rgb(30, 152, 215);
}

$zero-palette-primary: mat.define-palette(
  (
    100: var(--zero-primary-100),
    200: var(--zero-primary-200),
    300: var(--zero-primary-300),
    400: var(--zero-primary-400),
    500: var(--zero-primary-500),
    600: var(--zero-primary-600),
    700: var(--zero-primary-700),
    800: var(--zero-primary-800),
    900: var(--zero-primary-900),
    contrast: (
      100: var(--zero-black-000),
      200: var(--zero-black-000),
      300: var(--zero-black-000),
      400: var(--zero-white-000),
      500: var(--zero-white-000),
      600: var(--zero-white-000),
      700: var(--zero-white-000),
      800: var(--zero-white-000),
      900: var(--zero-white-000),
    ),
  )
);

$zero-palette-accent: mat.define-palette(
  (
    100: var(--zero-accent-100),
    200: var(--zero-accent-200),
    300: var(--zero-accent-300),
    400: var(--zero-accent-400),
    500: var(--zero-accent-500),
    600: var(--zero-accent-600),
    700: var(--zero-accent-700),
    800: var(--zero-accent-800),
    900: var(--zero-accent-900),
    contrast: (
      100: var(--zero-black-000),
      200: var(--zero-black-000),
      300: var(--zero-black-000),
      400: var(--zero-white-000),
      500: var(--zero-white-000),
      600: var(--zero-white-000),
      700: var(--zero-white-000),
      800: var(--zero-white-000),
      900: var(--zero-white-000),
    ),
  )
);

$zero-palette-warn: mat.define-palette(
  (
    100: var(--zero-warn-100),
    200: var(--zero-warn-200),
    300: var(--zero-warn-300),
    400: var(--zero-warn-400),
    500: var(--zero-warn-500),
    600: var(--zero-warn-600),
    700: var(--zero-warn-700),
    800: var(--zero-warn-800),
    900: var(--zero-warn-900),
    contrast: (
      100: var(--zero-black-000),
      200: var(--zero-black-000),
      300: var(--zero-black-000),
      400: var(--zero-white-100),
      500: var(--zero-white-000),
      600: var(--zero-white-000),
      700: var(--zero-white-000),
      800: var(--zero-white-000),
      900: var(--zero-white-000),
    ),
  )
);

$zero-palette-system-substructure: mat.define-palette(
  (
    100: var(--zero-system-substructure),
    500: var(--zero-system-substructure),
    700: var(--zero-system-substructure),
    contrast: (
      100: var(--zero-black-100),
      500: var(--zero-black-100),
      700: var(--zero-black-100),
    ),
  )
);

$zero-palette-system-superstructure: mat.define-palette(
  (
    100: var(--zero-system-superstructure),
    500: var(--zero-system-superstructure),
    700: var(--zero-system-superstructure),
    contrast: (
      100: var(--zero-black-100),
      500: var(--zero-black-100),
      700: var(--zero-black-100),
    ),
  )
);

$zero-palette-system-mechanical-services: mat.define-palette(
  (
    100: var(--zero-system-mechanical-services),
    500: var(--zero-system-mechanical-services),
    700: var(--zero-system-mechanical-services),
    contrast: (
      100: var(--zero-black-100),
      500: var(--zero-black-100),
      700: var(--zero-black-100),
    ),
  )
);

$zero-palette-system-electrical-services: mat.define-palette(
  (
    100: var(--zero-system-electrical-services),
    500: var(--zero-system-electrical-services),
    700: var(--zero-system-electrical-services),
    contrast: (
      100: var(--zero-white-000),
      500: var(--zero-white-000),
      700: var(--zero-white-000),
    ),
  )
);

$zero-palette-system-building-envelope: mat.define-palette(
  (
    100: var(--zero-system-building-envelope),
    500: var(--zero-system-building-envelope),
    700: var(--zero-system-building-envelope),
    contrast: (
      100: var(--zero-black-100),
      500: var(--zero-black-100),
      700: var(--zero-black-100),
    ),
  )
);

$zero-palette-system-public-health-and-hydraulics: mat.define-palette(
  (
    100: var(--zero-system-public-health-and-hydraulics),
    500: var(--zero-system-public-health-and-hydraulics),
    700: var(--zero-system-public-health-and-hydraulics),
    contrast: (
      100: var(--zero-black-100),
      500: var(--zero-black-100),
      700: var(--zero-black-100),
    ),
  )
);

$zero-palette-system-space-plan: mat.define-palette(
  (
    100: var(--zero-system-space-plan),
    500: var(--zero-system-space-plan),
    700: var(--zero-system-space-plan),
    contrast: (
      100: var(--zero-white-000),
      500: var(--zero-white-000),
      700: var(--zero-white-000),
    ),
  )
);

$zero-light-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-primary, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-primary, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-system-substructure-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-system-substructure, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-system-superstructure-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-system-superstructure, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-system-mechanical-services-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-system-mechanical-services, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-system-electrical-services-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-system-electrical-services, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-system-building-envelope-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-system-building-envelope, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-system-public-health-and-hydraulics-theme: mat.define-light-theme(
  (
    color: (
      primary:
        mat.define-palette(
          $zero-palette-system-public-health-and-hydraulics,
          500
        ),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);

$zero-system-space-plan-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($zero-palette-system-space-plan, 500),
      accent: mat.define-palette($zero-palette-accent, 500),
      warn: mat.define-palette($zero-palette-warn, 500),
    ),
    density: $zero-material-density,
  )
);
