div.zero-asset-list {
  display: grid;
  gap: var(--arc-spacing-medium);
  grid-auto-rows: 1fr;

  div.zero-card {
    height: 100%;
    min-height: 455px;
  }

  @include responsive-breakpoint('large') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include responsive-breakpoint('x-large') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
