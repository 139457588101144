.chart {
  svg g path {
    stroke-width: 6;
  }
  .Operational {
    fill: var(--zero-systems-card-operational-background);
    stroke: var(--zero-systems-card-operational-background);
  }
  .Embodied {
    fill: var(--zero-systems-card-embodied-background);
    stroke: var(--zero-systems-card-embodied-background);
  }
  .Mechanical {
    fill: var(--zero-system-mechanical-services);
    stroke: var(--zero-system-mechanical-services);
  }
  .Electrical {
    fill: var(--zero-system-electrical-services);
    stroke: var(--zero-system-electrical-services);
  }
  .Public {
    fill: var(--zero-system-public-health-and-hydraulics);
    stroke: var(--zero-system-public-health-and-hydraulics);
  }
  .Building {
    fill: var(--zero-system-building-envelope);
    stroke: var(--zero-system-building-envelope);
  }
  .Space {
    fill: var(--zero-system-space-plan);
    stroke: var(--zero-system-space-plan);
  }
  .Superstructure {
    fill: var(--zero-system-superstructure);
    stroke: var(--zero-system-superstructure);
  }
  .Substructure {
    fill: var(--zero-system-substructure);
    stroke: var(--zero-system-substructure);
  }
  .outScope {
    fill: transparent;
  }
  .highlight {
    stroke: #ffffff;
  }
}

/* d3 sunburst tooltip classes */
div.tooltip {
  position: absolute;
  width: 200px;
  height: auto;
  padding: 10px;
  display: none;
  background-color: rgb(var(--arc-container-color));
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  pointer-events: none;
  z-index: 99;
}

div.tooltip p {
  margin: 0;
  font-family: sans-serif;
  font-size: var(--arc-font-size-medium);
  line-height: var(--arc-line-height-normal);
}

// Central text

.center-text {
  fill: rgb(var(--arc-font-color));

  &--italic {
    font-style: italic;
    font-size: var(--arc-font-size-x-large);
  }
  &--large {
    font-size: var(--arc-font-size-xx-large);
  }
}
