@use 'variables' as variables;

@mixin responsive-breakpoint($breakpoint) {
  $raw-query: map-get(variables.$zero-breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`';
  }
}
